import React from 'react'

const ThankyouPage = () => {
  return (
    <>
      <h1>Contact</h1>
      <p>Thank you for your submission!</p>
    </>
  )
}

export default ThankyouPage
